import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Send, Slack, ArrowRight } from "react-feather"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import WorldMap from "../components/worldmap"
import Interviews from "../components/interviews"

export default class Community extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showEcosystemsLink: false,
      showNextLink: false,
      showPrevLink: false,
    }

    this.escFunction = this.escFunction.bind(this)
  }

  escFunction(event) {
    // Esc key press
    if (event.keyCode === 27) {
      this.setState({
        showEcosystemsLink: true,
      })

      setTimeout(() => {
        let linkRef = document.getElementById("esc-action")
        linkRef.click()
      }, 50)
    }

    // Left Arrow key press
    if (event.keyCode === 37) {
      this.setState({
        showPrevLink: true,
      })

      setTimeout(() => {
        let prevLinkRef = document.getElementById("prev-action")
        prevLinkRef.click()
      }, 50)
    }

    // Right Arrow key press
    if (event.keyCode === 39) {
      this.setState({
        showNextLink: true,
      })

      setTimeout(() => {
        let nextLinkRef = document.getElementById("next-action")
        nextLinkRef.click()
      }, 50)
    }
  }
  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false)
  }

  render() {
    return (
      <Layout>
        {this.state.showEcosystemsLink && (
          <AniLink id="esc-action" fade to="/" duration={0.4}></AniLink>
        )}
        {this.state.showPrevLink && (
          <AniLink
            id="prev-action"
            fade
            to="/interviews/estonia/"
            duration={0.4}
          ></AniLink>
        )}
        {this.state.showNextLink && (
          <AniLink
            id="next-action"
            fade
            to="/interviews/evarvest/"
            duration={0.4}
          ></AniLink>
        )}
        <SEO title="Founders Community" />

        <div id="landing-top">
          {/* <div id="contains-map">
    <div id="map-container">
    <WorldMap />
    </div>
    </div> */}
          <div id="left-col">
            <div id="left-col-content">
              {/* <div className="page-icon-container">
          <Mic className="page-icon" />
        </div> */}
              <h1 id="interviews-header">Community</h1>
              <h2 className="community-description">
                Join our community of founders building startups on Telegram to
                explore your incorporation and immigration options.
              </h2>
            </div>
          </div>
          <div id="right-col">
            <OutboundLink
              href="https://t.me/joinchat/Jhr2gxL3mambwfZlapQY3A"
              target="_blank"
            >
              <div className="lp-item-container">
                <div className="lp-item-header">
                  <div className="item-icon-container">
                    <Send className="header-icon" />
                  </div>
                  Join Telegram
                  <div className="item-arrow">
                    <ArrowRight />
                  </div>
                </div>
                <div className="item-description">
                  <p>
                    Join our community of founders building startups on Telegram
                    to explore your incorporation and immigration options.
                  </p>
                </div>
              </div>
            </OutboundLink>
          </div>
        </div>
      </Layout>
    )
  }
}
